<template>
  <div>
    <v-divider></v-divider>
    <v-layout wrap fill-height>
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down" v-if="$route.name !== 'mapping'">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-4 align-self-center>
              <v-img
                contain
                width="100%"
                height="150px"
                src="../assets/hwclogojpg.jpg"
                fill-height
              ></v-img>
            </v-flex>
          </v-layout>
          <!-- <v-layout wrap justify-center>
            <v-flex xs12 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn depressed dark  :color="activeSection=='hwc'? 'red' : ''" @click="activeSection = 'hwc'">HWC</v-btn>
            </v-flex>
            <v-flex xs12 md6 sm6 lg6 class="text-center">
              <v-btn depressed dark :color="activeSection=='sarpa'? 'red' : ''" @click="activeSection = 'sarpa'">SARPA</v-btn>
            </v-flex>
          </v-layout> -->
          <v-layout wrap justify-center>
            <v-flex xs12 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn depressed dark :color="activeSection=='hwc'? 'red' : ''" @click="setActiveSection('hwc')">HWC</v-btn>
            </v-flex>
            <v-flex xs12 md6 sm6 lg6 class="text-center">
              <v-btn depressed dark :color="activeSection=='sarpa'? 'red' : ''" @click="setActiveSection('sarpa')">SARPA</v-btn>
            </v-flex>
          </v-layout>

          <v-list dense nav v-if="activeSection === 'hwc'">
            <v-list-item
              v-for="item in items"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>

            <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ dynamic.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="dynamic.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in dynamic.dynamicItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>

            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->

          </v-list>
          <v-list dense nav v-if="activeSection === 'sarpa'">
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>

            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->

          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0 v-if="activeSection === 'hwc'">
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0 v-if="activeSection === 'sarpa'">
            <SarpaAppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'adminDashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AppHeader from "@/components/Common/appHeader";
import SarpaAppHeader from "@/components/Common/appHeadersarpa";

export default {
  components: {
    AppHeader,
    SarpaAppHeader
  },
  data() {
    return {
      activeSection: localStorage.getItem('activeSection') || 'hwc',
      items: [
        // {
        //   title: "Dashboard",
        //   icon: "mdi-view-dashboard",
        //   path: "/Admin/admindashboard",
        // },
        {
          title: "Home",
          icon: "mdi-view-dashboard",
          path: "/Admin/admindashboard",
        },
        // {
        //   title: "Report Case",
        //   icon: "mdi-account-plus",
        //   path: "/Admin/reportCase",
        // },
        { title: "My Details", icon: "mdi-account", path: "/Admin/profile" },
        // { title: "Public", icon: "mdi-account-group", path: "/Admin/public" },
        // {
        //   title: "Conflict Reports",
        //   icon: "mdi-source-repository-multiple",
        //   path: "/Admin/ConflictReports",
        // },
        // {
        //   title: "Analytics",
        //   icon: "mdi-home-analytics",
        //   path: "/admin/analyticspage",
        // },
        {
          title: "Dashboard",
          icon: "mdi-home-analytics",
          path: "/admin/analyticspage",
        },
        {
          title: "Statistics ",
          icon: "mdi-chart-bar",
          path: "/admin/commonanalyticspage",
        },
        // {
        //   title: "Common",
        //   icon: "mdi-home-analytics",
        //   path: "/admin/commonanalyticspage",
        // },
        {
          title: "Add Emergency User",
          icon: "mdi-account-plus",
          path: "/Admin/emergencyUser",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Admin/notifications",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/reportGeneration",
        },
        // {
        //   title: "All Reportings",
        //   icon: "mdi-file-chart",
        //   path: "/Admin/myReport",
        // },
        // {
        //   title: "Merged Cases",
        //   icon: "mdi-call-merge",
        //   path: "/Admin/mergedlist",
        // },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Admin/closedcases",
        },
        {
          title: "Map",
          icon: "mdi-map-search-outline",
          path: "/Admin/mapping",
        },
      ],
      sarpadashboard: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Sarpa/dashboard",
        },
        {
          title: "Statistics ",
          icon: "mdi-chart-bar",
          path: "/admin/commonanalyticspage",
        },
        {
          title: "Add Snakes",
          icon: "mdi-plus",
          path: "/addSnakes",
        },
        {
          title: "All Users",
          icon: "mdi-account-multiple",
          path: "/usersList",
        },
        {
          title: "New Rescuers",
          icon: "mdi-file-plus",
          path: "/newrescuerslist",
        },
        {
          title: "Rescuer List",
          icon: "mdi-format-list-bulleted",
          path: "/allrescuerlist",
        },
        {
          title: "Banned Rescuers",
          icon: "mdi-file-cancel-outline",
          path: "/bannedrescuerlist",
        },
        {
          title: "Approve All",
          icon: "mdi-check-decagram-outline",
          path: "/approveoperations",
        },
        {
          title: "Pending Operations",
          icon: "mdi-receipt-clock-outline",
          path: "/pendingoperations",
        },
        {
          title: "Assigned Operations",
          icon: "mdi-clipboard-check",
          path: "/assignedoperations",
        },
        {
          title: "Rescued Operations",
          icon: "mdi-invoice-list",
          path: "/rescuedoperations",
        },
        {
          title: "Released Operations",
          icon: "mdi-view-list",
          path: "/releasedoperations",
        },
        {
          title: "Approved Operations",
          icon: "mdi-playlist-check",
          path: "/approvedoperations",
        },
        {
          title: "Delete Operations",
          icon: "mdi-delete-alert",
          path: "/deleteoperations",
        },
        {
          title: "Training List",
          icon: "mdi-chair-school",
          path: "/traininglist",
        },
        {
          title: "Snake Reports",
          icon: "mdi-file-chart",
          path: "/snakereports",
        },
        {
          title: "Add Snake Experts",
          icon: "mdi-plus",
          path: "/addsnakeexperts",
        },
        {
          title: "Add Hospital",
          icon: "mdi-hospital-building",
          path: "/addhospital",
        },
        {
          title: "View Hospital",
          icon: "mdi-hospital-marker",
          path: "/Viewhospital",
        },
        {
          title: "Identification Help",
          icon: "mdi-help-box-multiple-outline",
          path: "/identificationhelp",
        },
        {
          title: "Excel",
          icon: "mdi-file-excel",
          path: "/excelreport",
        },
        // {
        //   title: "Approve Hospital",
        //   icon: "mdi-check-decagram-outline",
        //   path: "/approvehospital",
        // },
      ],

      dynamic: {
        title: "Dynamic Data",
        icon: "mdi-folder",
        visible: false,
        dynamicItems: [
          {
            title: "Scenerios",
            path: "/Admin/dynamicData/scenerios",
          },
          {
            title: "Animals",
            path: "/Admin/dynamicData/animals",
          },
          {
            title: "Animal Activities",
            path: "/Admin/dynamicData/animalActivities",
          },
          {
            title: "Animal Status",
            path: "/Admin/dynamicData/animalStatus",
          },
          // {
          //   title: "Animal Age",
          //   path: "/Admin/dynamicData/animalAge",
          // },
          // {
          //   title: "Veterinary Recommendation",
          //   path: "/Admin/dynamicData/veterinaryRecommendation",
          // },
          {
            title: "Mitigations",
            path: "/Admin/dynamicData/mitigationTypes",
          },
          {
            title: "Land Types",
            path: "/Admin/dynamicData/landTypes",
          },
          {
            title: "Gender",
            path: "/Admin/dynamicData/gender",
          },
          // {
          //   title: "Close Status",
          //   path: "/Admin/dynamicData/closeStatus",
          // },
          // {
          //   title: "Release Types",
          //   path: "/Admin/dynamicData/releaseTypes",
          // },
          {
            title: "Breach Types",
            path: "/Admin/dynamicData/breachType",
          },
          {
            title: "Barrier Types",
            path: "/Admin/dynamicData/barrierType",
          },
          {
            title: "Conflict Types",
            path: "/Admin/dynamicData/conflictType",
          },
          {
            title: "Crop Types",
            path: "/Admin/dynamicData/cropType",
          },
        ],
      },

      sarpa: {
        title: "SARPA",
        icon: "mdi-snake",
        visible: false,
        sarpaItems: [
          {
            title: "Dashboard",
            path: "/Sarpa/dashboard",
          },
          {
            title: "Add Snakes",
            path: "/addSnakes",
          },
          {
            title: "All Users",
            path: "/usersList",
          },
          {
            title: "New Rescuers",
            path: "/newrescuerslist",
          },
          {
            title: "Rescuer List",
            path: "/allrescuerlist",
          },
          {
            title: "Pending Operations",
            path: "/pendingoperations",
          },
          {
            title: "Assigned Operations",
            path: "/assignedoperations",
          },
          {
            title: "Rescued Operations",
            path: "/rescuedoperations",
          },
          {
            title: "Released Operations",
            path: "/releasedoperations",
          },
          {
            title: "Approved Operations",
            path: "/approvedoperations",
          },
          {
            title: "Delete Operations",
            path: "/deleteoperations",
          },
          {
            title: "Training List",
            path: "/traininglist",
          },
          {
            title: "Snake Reports",
            path: "/snakereports",
          },
          {
            title: "Add Snake Experts",
            path: "/addsnakeexperts",
          },
          {
            title: "Add Hospital",
            path: "/addhospital",
          },
          {
            title: "View Hospital",
            path: "/Viewhospital",
          },
          {
            title: "Identification Help",
            path: "/identificationhelp",
          },
          {
            title: "Approve Hospital",
            path: "/approvehospital",
          },
        ],
      },


      right: null,
    };
  },
  methods: {
    setActiveSection(section) {
      if(section === 'hwc'){
        this.$router.push('/admin/analyticspage');
      }
      if(section === 'sarpa'){
        this.$router.push('/Sarpa/dashboard');
      }
      this.activeSection = section;
      localStorage.setItem('activeSection', section); // Save to localStorage
    }
  },
};
</script>
